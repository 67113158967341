<template>
  <FormInputPrimary typeForm="edit"></FormInputPrimary>
</template>
<script>
import FormInputPrimary from '@/views/products/linkToPrimaryData/formInputPrimary';

export default {
  components: { FormInputPrimary },
};
</script>
